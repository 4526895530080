<template>
  <div class="monitor-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select v-model="listfilter.dangerid" filterable remote reserve-keyword default-first-option clearable
              placeholder="请选择监测点" size="medium" :remote-method="getDangerList" :loading="getDangerLoading"
              @change="onSearch">
              <el-option v-for="item in dangerSelect" :key="item.Id" :label="item.DangerName" :value="item.Id">
              </el-option>
            </el-select>

            <el-date-picker v-model="filterDaterange" type="daterange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" size="medium" style="width: 240px; margin-left: 10px" value-format="yyyy-MM-dd"
              @change="dateChange">
            </el-date-picker>

            <el-button style="margin-left: 10px" type="primary" size="medium" icon="el-icon-search" @click="onSearch">查询
            </el-button>
          </el-form>
        </div>
        <div class="action-right">
          <el-button style="margin-left: 10px" type="primary" size="medium" icon="el-icon-plus" @click="onCreateReport">
            生成自动报告
          </el-button>
        </div>
      </div>
    </div>

    <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
      :stripe="true" :border="false" v-loading="tableLoading">
      <el-table-column label="监测点名称" prop="DangerName"></el-table-column>
      <el-table-column label="报告名称" prop="Name"></el-table-column>
      <!-- <el-table-column label="文件地址" prop="FilePath"></el-table-column> -->

      <el-table-column label="报告时间" prop="StartTime">
        <template slot-scope="scope">
          {{ scope.row.StartTime | datetime('yyyy-MM-dd') }} -
          {{ scope.row.EndTime | datetime('yyyy-MM-dd') }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="IsDo">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.IsDo == 0">未生成</el-tag>
          <el-tag size="small" v-else type="success">已生成</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="CreateTime">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <a :href="scope.row.FilePath" target="_blank">
            <el-button type="success" icon="el-icon-view" size="mini">下载报告</el-button>
          </a>
          <el-button type="warning" icon="el-icon-error" size="mini" style="margin-left: 10px"
            @click="onListDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination background layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange">
    </pagination>


    <el-dialog title="生成自动报告" v-if="dialogReportVisible" :visible.sync="dialogReportVisible" width="550px"
      custom-class="geology-dialog" :modal-append-to-body="true" :destroy-on-close="true">
      <el-form ref="formReport" class="geology-form" :rules="reportFormRules" :model="reportForm" label-width="150px"
        style="margin-top: 30px">
        <el-form-item label="请选择监测点" prop="DangerId">
          <el-select style="width:80%;" v-model="reportForm.DangerId" filterable remote reserve-keyword
            default-first-option clearable placeholder="请选择监测点" size="medium" :remote-method="getDangerList"
            :loading="getDangerLoading">
            <el-option v-for="item in dangerSelect" :key="item.Id" :label="item.DangerName" :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请选择开始时间" prop="StartTime">
          <el-date-picker style="width:80%;" v-model="reportForm.StartTime" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="请选择结束时间" prop="EndTime">
          <el-date-picker style="width:80%;" v-model="reportForm.EndTime" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>

      <div class="dialog-footer" style="text-align: right; padding: 10px 0 20px 0">
        <el-button type="success" icon="el-icon-edit" size="small" @click="onReportSubmit">立即生成</el-button>
        <el-button size="small" style="margin-left: 10px" plain type="primary" @click="dialogReportVisible = false">关 闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getDangerList } from "@/api/base";
import { getDeviceReportList, createDeviceReport, deleteDeviceReport } from "@/api/device";
export default {
  data() {

    var validateEndTime = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择结束时间'));
      } else if (value < this.reportForm.StartTime) {
        callback(new Error('结束时间必须大于开始时间!'));
      } else {
        callback();
      }
    };
    return {
      listData: [],
      tableLoading: false,
      filterDaterange: "",

      getDangerLoading: false,
      dangerSelect: [],

      listfilter: {
        dangerid: "",
        fname: "",
        fstarttime: "",
        fendtime: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },

      dialogReportVisible: false,
      reportForm: {
        DangerId: '',
        StartTime: '',
        EndTime: '',
      },
      reportFormRules: {
        DangerId: [{
          required: true, trigger: "blur", message: "请选择监测点",
        }],
        StartTime: [{
          required: true, trigger: "blur", message: "请选择开始时间",
        }],
        EndTime: [{
          required: true, trigger: "blur", message: "请选择结束时间",
        }, { validator: validateEndTime, trigger: 'blur' }]
      }
    };
  },
  components: {},
  methods: {
    getDangerList(value) {
      this.getDangerLoading = true;
      getDangerList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDangerLoading = false;
        if (resdata.code == 0) {
          this.dangerSelect = resdata.data.data;
        }
      });
    },
    dateChange(value) {
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getDeviceReportList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDeviceReport(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => { });
    },
    onCreateReport() {
      this.reportForm = {
        DangerId: '',
        StartTime: '',
        EndTime: '',
      };
      this.dialogReportVisible = true;
    },
    onReportSubmit() {
      this.$refs["formReport"].validate((valid) => {
        if (valid) {
          createDeviceReport(this.reportForm).then(res => {
            if (res.code == 0) {
              this.$message.success("提交成功，请稍候下载报告");
              this.getListData();
              this.dialogReportVisible = false;
            } else {
              this.$message.error("提交失败!" + res.msg);
            }
          })
        }
      })
    },
  },
  created() {
    this.getDangerList();
    this.getListData();
  },
};
</script>